
// @ts-nocheck
import { Card } from "@num/component-library";
import Vue from "vue";
import { mapMutations } from "vuex";

import Flag from "@/components/Flag.vue";

import { SET_COUNTRY_CODE_USER_SELECTED } from "@/store/types/mutations";

import { getCountryCodeDisplay } from "@/utils/utils";

export default Vue.extend({
  name: "CountryCard",
  components: {
    Card,
    Flag,
  },
  props: {
    countryCode: String,
  },
  data() {
    return {
      getCountryCodeDisplay,
      gb: {
        millionCompanies: 5,
        millionDataPoints: 25,
      },
      us: {
        millionCompanies: 25,
        millionDataPoints: 160,
      },
    };
  },
  computed: {
    countryData() {
      return this[this.countryCode];
    },
    countryName() {
      switch (this.countryCode) {
        case "gb":
          return "United Kingdom";
        case "us":
          return "United States of America";
        default:
          return null;
      }
    },
  },
  methods: {
    ...mapMutations([SET_COUNTRY_CODE_USER_SELECTED]),
    setCountryVersion() {
      this.SET_COUNTRY_CODE_USER_SELECTED(this.countryCode);
    },
  },
});
