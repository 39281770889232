
import Vue from "vue";
import { mapState } from "vuex";

import Container from "@/components/Container.vue";
import CountryCard from "@/components/CountryCard.vue";
import Hero from "@/components/Hero.vue";

import { redirectToVersion } from "@/utils/utils";

export default Vue.extend({
  name: "CountryIndex",
  components: {
    Container,
    CountryCard,
    Hero,
  },
  computed: {
    ...mapState(["settings"]),
  },
  created() {
    // we don't want to use `countryCodeRoute` here, as this might still have
    // an old value in it, so we'll use the user selected version or IP version
    // if either exist, else let the user pick a country
    const countryCodeToUse =
      this.settings.countryCodeUserSelected || this.settings.countryCodeIp;
    if (countryCodeToUse) redirectToVersion(countryCodeToUse);
  },
});
